/** @jsx jsx */
import { jsx } from "theme-ui"
import React from "react"
import { GoFold, GoUnfold } from "react-icons/go"
import { hexToRGBA } from "gatsby-interface"

const iconStyles = {
  display: `inline-block`,
  mr: t => t.space[3],
}

const ExpandAllButton = ({ onClick, expandAll }) => (
  <button
    onClick={onClick}
    sx={{
      alignItems: `center`,
      bg: `transparent`,
      border: `none`,
      borderRadius: 1,
      color: `text.secondary`,
      cursor: `pointer`,
      display: `flex`,
      flexGrow: 0,
      fontSize: 0,
      lineHeight: `solid`,
      py: t => t.space[3],
      textAlign: `left`,
      transition: t => `all ${t.transitions.speed.fast}`,
      "&:hover": {
        bg: t => hexToRGBA(t.colors.purple[20], 0.275, true),
        color: `purple.60`,
      },
    }}
  >
    {expandAll ? (
      <React.Fragment>
        <span sx={iconStyles}>
          <GoFold />
        </span>
        <span>Collapse All</span>
      </React.Fragment>
    ) : (
      <React.Fragment>
        <span sx={iconStyles}>
          <GoUnfold />
        </span>
        <span>Expand All</span>
      </React.Fragment>
    )}
  </button>
)

export default ExpandAllButton
