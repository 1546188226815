/** @jsx jsx */
import { jsx } from "theme-ui"
import { useState, Fragment } from "react"

import Sidebar from "./sidebar"
import ScrollSyncSidebar from "./scroll-sync-sidebar"
import ChevronSvg from "./chevron-svg"
import { HeaderHeight, SidebarWidth } from "../shared/constants/layout"

export default function StickyResponsiveSidebar(props) {
  const [isOpen, setIsOpen] = useState(false)

  const { enableScrollSync, CustomSidebar } = props
  const SidebarComponent =
    CustomSidebar || (enableScrollSync ? ScrollSyncSidebar : Sidebar)
  const iconOffset = isOpen ? 5 : -5
  const menuOpacity = isOpen ? 1 : 0

  return (
    <Fragment>
      <div
        sx={theme => ({
          border: 0,
          bottom: 0,
          display: `block`,
          height: [`100vh`, null, null, `calc(100vh - ${HeaderHeight})`],
          top: [0, null, null, HeaderHeight],
          overflowY: `auto`,
          position: `fixed`,
          transition: `opacity ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
          width: SidebarWidth.mobile,
          zIndex: 9999,
          [theme.mediaQueries.tablet]: {
            maxWidth: `none`,
            opacity: `1 !important`,
            pointerEvents: `auto`,
            width: SidebarWidth.default,
          },
          [theme.mediaQueries.desktop]: {
            width: SidebarWidth.large,
          },
          opacity: menuOpacity,
          pointerEvents: isOpen ? `auto` : `none`,
        })}
      >
        <div
          sx={theme => ({
            boxShadow: `dialog`,
            height: `100%`,
            transform: isOpen
              ? `translateX(0)`
              : `translateX(-${SidebarWidth.mobile})`,
            transition: `transform ${theme.transitions.speed.default} ${theme.transitions.curve.default}`,
            [theme.mediaQueries.tablet]: {
              boxShadow: `none`,
              transform: `none !important`,
            },
          })}
        >
          <SidebarComponent closeSidebar={() => setIsOpen(false)} {...props} />
        </div>
      </div>
      <div
        sx={theme => ({
          backgroundColor: `gatsby`,
          borderRadius: `50%`,
          bottom: theme.space[6],
          boxShadow: `dialog`,
          cursor: `pointer`,
          display: `flex`,
          height: theme.space[11],
          justifyContent: `space-around`,
          position: `fixed`,
          right: theme.space[7],
          visibility: `visible`,
          width: theme.space[11],
          zIndex: 20,
          [theme.mediaQueries.tablet]: { display: `none` },
        })}
        onClick={() => setIsOpen(isOpen => !isOpen)}
        // TODO verify the a11y and keyboard implications of this
        onKeyDown={() => setIsOpen(isOpen => !isOpen)}
        role="button"
        aria-label="Show Secondary Navigation"
        aria-controls="SecondaryNavigation"
        aria-expanded={isOpen ? `true` : `false`}
        tabIndex={0}
      >
        <div
          sx={theme => ({
            alignSelf: `center`,
            color: `white`,
            display: `flex`,
            flexDirection: `column`,
            height: theme.space[6],
            visibility: `visible`,
            width: theme.space[6],
          })}
        >
          <ChevronSvg
            size={16}
            cssProps={{
              transform: `translate(${iconOffset}px, 5px) rotate(90deg)`,
              transition: t =>
                `transform ${t.transitions.speed.default} ${t.transitions.curve.default}`,
            }}
          />
          <ChevronSvg
            size={16}
            cssProps={{
              transform: `translate(${5 - iconOffset}px, -5px) rotate(270deg)`,
              transition: t =>
                `transform ${t.transitions.speed.default} ${t.transitions.curve.default}`,
            }}
          />
        </div>
      </div>
    </Fragment>
  )
}
