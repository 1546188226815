/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { hexToRGBA } from "gatsby-interface"

import { useSidebarContext } from "./sidebar"
import indention from "../../utils/sidebar/indention"
import { SidebarItemMinHeight } from "../shared/constants/layout"

const _getTitle = (title, isDraft) => (isDraft ? title.slice(0, -1) : title)
const _isDraft = title => title.slice(-1) === `*`

const bulletSize = 8
const bulletSizeActive = 100
const bulletOffsetTop = `1.3em`

export default function ItemLink({ item, overrideCSS, isSteps }) {
  const { onLinkClick, getItemState } = useSidebarContext()
  const { isActive, inActiveTree } = getItemState(item)

  const isDraft = _isDraft(item.title)
  const title = _getTitle(item.title, isDraft)

  const level = item.level
  const indent = isSteps ? indention(level + 1) : indention(level)

  return (
    <span
      sx={{
        alignItems: `center`,
        display: `flex`,
        position: `relative`,
        "&:before": {
          bg: `transparent`,
          bottom: 0,
          content: `''`,
          height: 1,
          left: indent,
          position: `absolute`,
          right: 0,
          top: `auto`,
        },
      }}
    >
      <Link
        sx={{
          minHeight: SidebarItemMinHeight,
          position: `relative`,
          pl: indent,
          pr: t => t.space[5],
          py: t => t.space[4],
          textDecoration: `none`,
          width: `100%`,
          zIndex: 1,
          "&&": {
            border: 0,
            color: `grey.70`,
            fontWeight: `body`,
            ...(isDraft && {
              color: `text.secondary`,
            }),
            ...(inActiveTree && {
              color: `purple.50`,
              fontWeight: `semiBold`,
            }),
            "&:hover": {
              bg: t => hexToRGBA(t.colors.purple[20], 0.275, true),
              color: `purple.60`,
              "&:before": {
                bg: `purple.50`,
                transform: `scale(1)`,
              },
            },
            ...overrideCSS,
          },
          "&:before, &:after": {
            content: `''`,
            left: t =>
              level === 0 || (level === 1 && !isSteps)
                ? `calc(${indent} - ${t.space[5]})`
                : `calc(${indent} - ${t.space[6]})`,
            top: bulletOffsetTop,
            height: bulletSize,
            position: `absolute`,
            transition: t => t.transitions.default,
            width: bulletSize,
          },
          "&:before": {
            bg: isActive && `purple.50`,
            borderRadius: 6,
            transform: isActive ? `scale(1)` : `scale(0.1)`,
          },
          "&:after": {
            bg: `purple.50`,
            borderRadius: 2,
            opacity: isActive ? 1 : 0,
            transform: `translateX(-${bulletSizeActive - bulletSize}px)`,
            width: isActive ? bulletSizeActive : 0,
          },
        }}
        onClick={onLinkClick}
        to={item.link}
      >
        {isSteps && (
          <span
            sx={{
              bg: `grey.20`,
              borderColor: `grey.20`,
              borderRadius: 6,
              borderStyle: `solid`,
              borderWidth: `1px`,
              display: `block`,
              fontWeight: `body`,
              height: bulletSize,
              left: t => t.space[7],
              position: `absolute`,
              top: bulletOffsetTop,
              width: bulletSize,
              zIndex: -1,
            }}
          />
        )}
        {title}
      </Link>
    </span>
  )
}
