import { useEffect, useState } from "react"

export const useActiveHash = (itemIds, options = {}) => {
  const [activeHash, setActiveHash] = useState(``)

  useEffect(() => {
    if (typeof window === `undefined`) {
      return
    }
    const observer = new IntersectionObserver(
      entries => {
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            setActiveHash(entry.target.id)
          }
        })
      },
      { rootMargin: `0% 0% -80% 0%`, ...options }
    )

    const existingElements = itemIds
      .map(id => document.getElementById(id))
      .filter(Boolean)

    existingElements.forEach(el => {
      observer.observe(el)
    })

    return () => {
      existingElements.forEach(el => {
        observer.unobserve(el)
      })
    }
  }, [])

  return activeHash
}
