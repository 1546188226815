import React from "react"
import SidebarBody from "./sidebar"
import { useActiveHash } from "../hooks/use-active-hash"

const getItemIds = section => {
  const list = []

  if (!section) {
    return list
  }

  section.forEach(subSection => {
    if (subSection.hasOwnProperty(`hash`)) list.push(subSection.hash)
    if (subSection.items) list.push(...getItemIds(subSection.items))
  })

  return list
}

const ScrollSyncSection = props => {
  const { itemList, location } = props
  const section = itemList.find(item => item.link === location.pathname)?.items
  const items = getItemIds(section)
  const activeItemHash = useActiveHash(items, {
    root:
      typeof window === `undefined`
        ? null
        : document.getElementById("docSearch-content"),
  })

  return <SidebarBody activeItemHash={activeItemHash} {...props} />
}

export default ScrollSyncSection
