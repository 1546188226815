import { getTheme } from "gatsby-interface"

const space = getTheme().space

// :)
const remToPx = rem => parseFloat(rem) * 16
const pxToRem = pixels => `${pixels / 16}rem`

const indention = level =>
  level === 0 || level === 1 ? space[7] : pxToRem(level * remToPx(space[7]))

export default indention
